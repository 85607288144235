<template>
  <b-card
    :title=" Object.entries(service).length ? 'Edit '+details.title : 'Edit'"
  >
    <b-form @submit.prevent="saveChanges">
      <b-row>
        <b-col md="12">
          <b-form-group
            label="Title"
            label-for="mc-title"
          >
            <b-form-input
              id="mc-name"
              v-model="details.title"
              placeholder="Course Title"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Code"
            label-for="mc-code"
          >
            <b-form-input
              id="mc-code"
              v-model="details.code"
              placeholder="Course Code"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Type"
            label-for="mc-type"
          >
            <b-form-input
              id="mc-type"
              v-model="details.type"
              placeholder="Type"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Fee"
            label-for="mc-fee"
          >
            <b-form-input
              id="mc-fee"
              v-model="details.fee"
              placeholder="Course Fee"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Discount(%)"
            label-for="mc-discount"
          >
            <b-form-input
              id="mc-discount"
              v-model="details.discount"
              placeholder="Course Discount"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Duration"
            label-for="mc-duration"
          >
            <b-form-input
              id="mc-app-duration"
              v-model="details.duration"
              placeholder="Course duration"
            />
          </b-form-group>
        </b-col>
        <b-col md="12" lg="12">
          <b-form-group
            label="Description"
          >
            <b-form-textarea
              placeholder="Course Description"
              v-model="details.description"
              
            />
          </b-form-group>
        </b-col>
        <b-col md="7" lg="7">
          <b-form-group
            label="Course Cover"
          >
            <b-img
              :src="details.cover"
              fluid
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Save Changes
            <b-spinner
              v-show="loading"
              variant="light"
              small
            />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BSpinner, BFormTextarea, BImgLazy, BImg
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BFormGroup,
    BImgLazy,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BSpinner,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      service: {},
      details: {},
      loading: false,
    }
  },
  created() {
    this.getService()
  },
  methods: {
    getService() {
      this.$http.get(`${this.$url}/courses/${router.currentRoute.params.id}`)
        .then(response => {
          console.log(response.data.data)
          if (response.data.status) {
            this.service = response.data.data
            this.details.title = this.service.title
            this.details.type = this.service.type
            this.details.code = this.service.code
            this.details.fee = this.service.fee
            this.details.discount = this.service.discount
            this.details.duration = this.service.duration
            this.details.description = this.service.description
            this.details.cover = this.service.cover
          }
        })
    },
    saveChanges() {
      this.loading = true
      this.$http.put(`${this.$url}/courses/${router.currentRoute.params.id}`, this.details)
        .then(response => {
          if (response.data.status) {
            window.location.reload()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
